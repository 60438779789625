* {
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}

body {
  background: #F7F7F8;
}

.fancybox__container {
  z-index: 99999 !important;
}

.form-control {
  width: 100%;
  padding: 5px 0;
  padding-left: 10px;
  font-size: 16px;
  line-height: 140%;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(231, 237, 252, 0.77);
  border-radius: 4px;
  border: 1px solid #ececec;
}

.form-control:focus {
  border: 1px solid #88ffc0;
}

.form-control:focus {
  outline: none;
}

.form-control::placeholder {
  color: #b4bbc1;
}

.error-text {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #cf2c49;
  line-height: 140%;
}

@font-face {
  font-family: epiloge;
  src: url(../fonts/Epilogue-VariableFont_wght.ttf);
}
@font-face {
  font-family: Epiloge-Bold;
  src: url(../fonts/static/Epilogue-Bold.ttf);
}
@font-face {
  font-family: Epiloge-Black;
  src: url(../fonts/static/Epilogue-Black.ttf);
}
@font-face {
  font-family: Epiloge-Italic;
  src: url(../fonts/static/Epilogue-Italic.ttf);
}
@font-face {
  font-family: Epiloge-Light;
  src: url(../fonts/static/Epilogue-Light.ttf);
}
@font-face {
  font-family: Epiloge-Medium;
  src: url(../fonts/static/Epilogue-Medium.ttf);
}
@font-face {
  font-family: Epiloge-Regular;
  src: url(../fonts/static/Epilogue-Regular.ttf);
}
@font-face {
  font-family: Epiloge-SemiBold;
  src: url(../fonts/static/Epilogue-SemiBold.ttf);
}
@font-face {
  font-family: Epiloge-Thin;
  src: url(../fonts/static/Epilogue-Thin.ttf);
}
@font-face {
  font-family: Caveat-Bold;
  src: url(../fonts/Caveat-Bold.ttf);
}
@font-face {
  font-family: Caveat-Regular;
  src: url(../fonts/Caveat-Regular.ttf);
}
.epiloge-bold {
  font-family: "Epiloge-Bold", sans-serif;
}

.epiloge-black {
  font-family: "Epiloge-Black", sans-serif;
}

.epiloge-italic {
  font-family: "Epiloge-Italic", sans-serif;
}

.epiloge-light {
  font-family: "Epiloge-Light", sans-serif;
}

.epiloge-medium {
  font-family: "Epiloge-Medium", sans-serif;
}

.epiloge-regular {
  font-family: "Epiloge-Regular", sans-serif;
}

.inputDesign {
  background: #fff;
  box-shadow: 0 4px 28px rgba(231, 237, 252, 0.77);
  border-radius: 4px;
  outline: none;
  height: 30px;
  padding-left: 10px;
  width: 100%;
  resize: none;
  border: 1px solid #dbdbdb;
}

.activeEventButton {
  color: white !important;
  background-color: #08c65b !important;
}

.ratingsWrapper {
  display: inline-block;
  margin: 10px 0px;
  cursor: pointer;
}
.ratingsWrapper span {
  color: rgb(250, 175, 0) !important;
}

.ShadowBox {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 17.0139px;
}

.submitBtn {
  background: #12c76a;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  border: 0;
  width: 100%;
  padding: 6px;
  font-family: Poppins-Regular;
  font-size: 16px;
  position: relative;
}

.editBtn {
  padding: 5px;
}

.tableHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
.tableHeader span {
  font-size: 11px;
}

.tableHeader svg {
  margin-left: 10px;
}

.tableHeader input {
  border: 1px solid #D5D5D5;
  height: 24px;
  margin-top: 16px;
}

.tableHeader select {
  border: 1px solid #D5D5D5;
  height: 28px;
  margin-top: 16px;
  width: 120px;
}

.companies_avatarName {
  display: flex;
  align-items: center;
}
.companies_avatarName img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.companies_avatarName span {
  margin-left: 10px;
}

.tableWrapper {
  padding: 63px 21px;
}

.reportUsersWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminBtn {
  width: 121px;
  height: 32px;
  background: #4E8EFE;
  border-radius: 4px !important;
  cursor: pointer;
  color: white;
  border: 0px;
}

.ban {
  background-color: #EB4462;
}

.actions input {
  border: 0px;
  background-color: none;
}

.dashboardGraphicBox {
  padding-top: 68px;
  padding-bottom: 68px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 68px;
  margin-left: 5px;
  margin-right: 5px;
  background: #FFFFFF;
}

.cartBox {
  width: 100%;
  height: 87px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 4px 17px rgba(155, 164, 174, 0.24);
  border-radius: 6px;
  position: relative;
  margin-bottom: 60px;
  align-items: center;
  padding: 0px 110px 0px 140px;
}
.cartBox span {
  font-size: 20px;
  font-family: epiloge-regular;
}
.cartBox .circleIcon {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  position: absolute;
  left: 36px;
  top: -30px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-boxes {
  display: flex;
  align-items: center;
}
.btn-boxes button {
  width: 121px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 57px;
  background-color: transparent;
  margin: 0px 20px;
}
.btn-boxes .remove {
  background: #EB4462;
  color: white;
  border: 0px;
}

.dropDownIcon {
  position: absolute;
  right: 35px;
}

.editName {
  border: 0px;
  border-bottom: 1px solid #D4D4D4;
  padding-bottom: 8px;
  font-size: 20px;
}

.subCartBox {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  padding-left: 40px;
  padding-right: 112px;
}
.subCartBox button {
  border: 1px solid #000000;
  border-radius: 57px;
  cursor: pointer;
  background-color: transparent;
  padding: 5px 40px;
  transition: 0.4s;
  cursor: pointer;
}
.subCartBox button:hover {
  background-color: #000000;
  color: white;
}

.subCartBox:last-child {
  margin-bottom: 60px;
}

.disableInp {
  border: 0 !important;
  background-color: none;
}

.w3-blue,
.w3-hover-blue:hover {
  color: #fff !important;
  background-color: #00A95D !important;
  height: 8px;
  position: relative;
}

.w3-round-xlarge {
  position: relative;
}
.w3-round-xlarge .left {
  position: absolute;
  left: 50%;
  top: -20px;
  color: black;
}
.w3-round-xlarge .right {
  position: absolute;
  position: absolute;
  right: 10%;
  top: -20px;
  color: black;
}

.w3-round-xlarge {
  border-radius: 16px;
}

.right-percent {
  background-color: #EB4362;
}

.rolesBox {
  padding-top: 83px;
  padding-left: 55px;
  padding-right: 55px;
}

.box {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  border-radius: 4px;
  overflow: hidden;
}
.box .firstBoxBanner {
  height: 15px;
  background: #18ED7D;
}
.box .secondBoxBanner {
  height: 15px;
  background: #18ED7D;
  border-radius: 4px 4px 0px 0px;
}
.box .addMethod {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
.box .addMethod button {
  background-color: transparent;
  border: 0px;
}
.box .addIndMethod {
  display: flex;
  align-items: center;
}
.box .sectionsBar {
  padding: 23px 22px 0px 22px;
}
.box .sectionsBar .sectionsItem {
  height: 50px;
  background: #F4F8FB;
  border-radius: 10px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  color: #6984ED;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.box .sectionsBar .sectionsItem svg {
  width: 15px;
  height: 14px;
}
.box .sectionsBar .itemMethod {
  border: 1px solid #D0D0D0;
  border-radius: 10px;
  height: 40px;
  margin: 16px 0px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.box .sectionsBar .itemMethod svg {
  width: 15px;
  height: 14px;
}
.box .sectionsBar .mtItems {
  background: #F4F8FB;
  border: 1px solid #6984ED;
  border-radius: 10px;
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.box .sectionsWrapper {
  padding: 0px 20px 30px;
}
.box .sectionsWrapper .sectionsButton {
  width: 100%;
  background: rgba(135, 95, 243, 0.2);
  border-radius: 10px;
  border: 0;
  height: 50px;
  text-align: start;
  padding-left: 30px;
  color: rgb(135, 95, 243);
}
.box .line {
  border: 1px solid #D0D0D0;
}
.box .methodSelectionWrapper {
  margin: 30px 22px 50px;
}
.box .methodSelectionWrapper .methodSelectionItem {
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
}

.blogsBox {
  padding: 0px 60px;
  word-wrap: break-word;
}

.filter {
  padding: 60px 60px;
}

.blogsCard {
  width: 100%;
  padding: 0px 0px 20px 0px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  border-radius: 4px;
  margin-bottom: 60px;
}

.image {
  width: 100%;
}

.mainText {
  width: 100%;
  font-family: "Epiloge-regular";
  font-style: normal;
  font-size: 18px;
  line-height: 140%;
  color: #2E343B;
  padding: 30px 20px 0px 20px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text {
  padding: 0 20px 0 20px;
  width: 100%;
  font-family: "Epiloge-light";
  font-style: normal;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 140%;
  color: #2E343B;
  overflow: hidden;
  height: 45px;
}

.editButton {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  border-radius: 4px;
  width: 121px;
  height: 32px;
  border: 1px solid #000000;
  font-family: "Epiloge-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #1E1E42;
  margin-left: 20px;
  cursor: pointer;
}

.removeButton {
  background: #EB4462;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  border-radius: 4px;
  width: 121px;
  height: 32px;
  border: 1px solid #EB4462;
  font-family: "Epiloge-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
  cursor: pointer;
}

.editButton:hover {
  background: #1E1E42;
  color: #FFFFFF;
}

.search-btn {
  width: max-content;
  background-color: transparent;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #B9B9B9;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.search-btn svg {
  margin: 0px 10px 0px 20px;
}
.search-btn input {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 35px;
}
.search-btn input::placeholder {
  color: #B9B9B9;
}

.filterDate {
  box-shadow: 0px 4px 16px rgba(128, 135, 143, 0.25);
  border-radius: 4px;
  height: 35px;
  width: 120px;
}
.filterDate select {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0px;
  padding-left: 10px;
  color: #B9B9B9;
}

.filterBox {
  display: flex;
  justify-content: flex-end;
}

.blog-create {
  padding: 60px 64px;
}
.blog-create .inputsWrapper {
  padding: 30px 0px;
}
.blog-create .createWrapper {
  width: 100%;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  background-color: #FFFFFF;
}
.blog-create .createWrapper .banner {
  height: 15px;
  background-color: #18ED7D;
  border-radius: 4px 4px 0px 0px;
}

.createInp {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(155, 164, 174, 0.25);
  border-radius: 10px;
  border: 0;
  width: 100%;
  height: 45px;
  padding-left: 30px;
  color: #95A3B0;
}
.createInp::placeholder {
  color: #95A3B0;
}

.titleInp {
  margin-top: 54px;
  resize: none;
}

.descInp {
  padding: 15px 30px;
  height: 200px;
}

.selectImg {
  margin-top: 54px;
}

.dropImg {
  margin-top: 54px;
  border: 2px solid rgba(98, 98, 98, 0.2039215686);
  border-radius: 10px;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.dropImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dropImg label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dropImg button {
  width: 60px;
  height: 60px;
  background-color: rgba(98, 98, 98, 0.2039215686);
  border: 0px;
  border-radius: 100%;
  cursor: pointer;
}
.dropImg button svg {
  width: 30px;
  height: 30px;
}

.blogArea {
  padding: 20px 20px;
  resize: none;
  height: 250px !important;
}

.btnWrappers {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btnWrappers button {
  margin-left: 15px;
  cursor: pointer;
}
.btnWrappers button:first-child {
  border: 1px solid #875FF3;
  color: #875FF3;
  transition: 0.4s;
}
.btnWrappers button:first-child:hover {
  background-color: #875FF3;
  color: white;
}
.btnWrappers button:last-child {
  background: #EB4462;
  color: white;
  border: 1px solid #EB4462;
  transition: 0.4s;
}
.btnWrappers button:last-child:hover {
  background-color: white;
  color: #EB4462;
  border: 1px solid #EB4462;
}

.commonBtn {
  border-radius: 4px;
  padding: 8px 30px;
  border: 0px;
  background-color: transparent;
}

.ck-content {
  height: 190px;
}

.imageUpload {
  width: 100%;
  height: 45px;
  position: relative;
  display: block;
}
.imageUpload svg {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fakeInp {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blogsCard {
  overflow: hidden;
  padding-bottom: 15px;
}
.blogsCard img {
  height: 250px;
  object-fit: cover;
}

.imageBox {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.imageBox .imgWrapper {
  width: 150px;
  height: 100px;
  border-radius: 4px;
  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.1));
}
.imageBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.err-text {
  display: inline-block;
  color: #EB4462;
  margin-top: 10px;
  margin-left: 10px;
}

.admin-inp {
  width: 100%;
  border: 0px;
  height: 50px;
  background: #F4F8FB;
  border-radius: 10px;
}

.menu-box ul {
  padding: 20px;
}
.menu-box label {
  width: 100%;
}
.menu-box label span {
  color: #9F9F9F;
  font-size: 17px;
  cursor: pointer;
}
.menu-box input {
  padding-left: 15px;
}
.menu-box button {
  background: #1E1E42;
  border-radius: 4px;
  padding: 7px 30px;
  border: 0px;
  color: white;
  cursor: pointer;
}

.indCard {
  width: 100%;
  background: #F4F8FB;
  border-radius: 10px;
  margin-bottom: 15px;
  height: 45px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  padding-left: 30px;
  cursor: pointer;
}

.iconWrapper {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.iconWrapper svg {
  width: 20px;
  height: 20px;
}

.loading {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkActive path {
  fill: #20BC63;
}

.reactionWrapper {
  text-align: center;
}

.reactionImg {
  object-fit: contain;
}

.industriesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.searchBox {
  margin: 0px 15px 15px;
}

.sizedCatIndBox {
  height: 420px;
  overflow-y: scroll;
}

.industry {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  padding: 15px 25px;
}

.indItem {
  background: #F4F8FB;
  border-radius: 4px;
  padding: 10px 15px;
  width: max-content;
  cursor: pointer;
  text-align: center;
  vertical-align: center;
  margin: 7px;
  display: flex;
  align-items: center;
  transition: 0.5s;
}
.indItem span {
  cursor: pointer;
}
.indItem:hover {
  box-shadow: -1px 1px 19px -4px rgba(0, 0, 0, 0.15);
}
.indItem img {
  margin-right: 10px;
}

.categoriesItem {
  background: #F4F8FB;
  width: 100%;
  border-radius: 4px;
  padding: 15px 20px;
  margin: 0px 30px;
  margin-bottom: 20px;
}
.categoriesItem label {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.itemLabel {
  width: 100%;
}

.selectedImgBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.selectedImgBox button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedImgBox button svg {
  fill: white;
  width: 15px;
  height: 15px;
}

.companyAddheader {
  background: #F4F8FB;
  border: 1px solid rgba(30, 30, 66, 0.01);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 25px;
  font-size: 20px;
}
.companyAddheader .headerIconside {
  display: flex;
  align-items: center;
}
.companyAddheader .headerIconside svg {
  margin-right: 10px;
}
.companyAddheader label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.react-selectInput {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(155, 164, 174, 0.25);
  border-radius: 10px;
  border: 0;
  width: 100%;
  height: 45px;
  color: #95A3B0;
}

.inputDesign {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding: 6px 15px;
  border: none;
  color: #9F9F9F;
}

.checkbox {
  padding: 10px 20px;
  transform: translateZ(0);
}

.checkbox label {
  cursor: pointer;
  padding-left: 0;
}

.checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}

.checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.checkbox .checkbox-material:before {
  position: absolute;
  left: 8px;
  top: 2px;
  content: "";
  background-color: #5048E5;
  height: 4px;
  width: 4px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
}

.checkbox .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
}

.checkbox .checkbox-material .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards ease-out;
  animation: checkbox-off 0.3s forwards ease-out;
}

.checkbox input[type=checkbox]:focus + .checkbox-material .check:after {
  opacity: 0.2;
}

.checkbox input[type=checkbox]:checked + .checkbox-material .check:before {
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards ease-out;
  animation: checkbox-on 0.3s forwards ease-out;
}

.checkbox input[type=checkbox]:not(:checked) + .checkbox-material:before {
  -webkit-animation: rippleOff 700ms forwards ease-out;
  animation: rippleOff 700ms forwards ease-out;
}

.checkbox input[type=checkbox]:checked + .checkbox-material:before {
  -webkit-animation: rippleOn 700ms forwards ease-out;
  animation: rippleOn 700ms forwards ease-out;
}

.checkbox input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
  -webkit-animation: rippleOff 700ms forwards ease-out;
  animation: rippleOff 700ms forwards ease-out;
}

.checkbox input[type=checkbox]:checked + .checkbox-material .check:after {
  -webkit-animation: rippleOn 700ms forwards ease-out;
  animation: rippleOn 700ms forwards ease-out;
}

.checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before,
.checkbox input[type=checkbox][disabled] + .circle {
  opacity: 0.5;
}

.checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: #5048E5;
  transform: rotate(-45deg);
}

.coloured .checkbox-material .check {
  color: #2faef8;
}

.coloured .checkbox-material:before {
  background-color: #2faef8;
}

.coloured input[type=checkbox]:checked + .checkbox-material .check {
  color: #2faef8;
}

@-webkit-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@-webkit-keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
  }
}
@-webkit-keyframes rippleOn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@-webkit-keyframes rippleOff {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
.createAwardSearchBox {
  padding: 15px;
}

.companySearch {
  padding: 15px;
  width: 100%;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  border-radius: 4px;
  border: none;
  color: #626262;
}

.companyList {
  margin: 15px;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(155, 164, 174, 0.25);
  border-radius: 4px;
  color: #626262;
  font-size: 18px;
}
.companyList label {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 40px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.btnDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 70px;
  margin-bottom: 40px;
  position: relative;
}
.btnDiv .score {
  position: absolute;
  border-radius: 50%;
  background-color: gray;
  width: 40px;
  height: 40px;
  top: -20px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1D1D40;
  background: #FFFFFF;
}
.btnDiv .scorre {
  position: absolute;
  border-radius: 50%;
  background-color: gray;
  width: 40px;
  height: 40px;
  top: -20px;
  right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1D1D40;
  color: #FFFFFF;
}
.btnDiv .firstBtn {
  background: #1E1E42;
  box-shadow: 0px 4px 21px rgba(78, 142, 254, 0.33);
  color: white;
  border-radius: 14px;
  padding: 15px 55px;
  cursor: pointer;
  border: none;
  position: relative;
}
.btnDiv .secondtBtn {
  background: #FFFFFF;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  color: #A8A8A8;
  padding: 15px 70px;
  cursor: pointer;
  border: none;
  margin-left: 35px;
  margin-right: 44px;
}

.greenLine {
  background: #18ED7D;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  padding: 4px 0;
}

.businessLine {
  background: #F4F8FB;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.businessLine .bunines {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.businessLine .bunines span {
  color: #1E1E42;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  display: inline-block;
  margin-left: 44px;
}
.businessLine .addList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 44px;
}
.businessLine .addList svg {
  margin-left: 35px;
  cursor: pointer;
}
.businessLine .addList span {
  display: inline-block;
  color: #606060;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
}

.table {
  background: #FFFFFF;
  box-shadow: 1px 4px 25px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 35px 0;
  width: 100%;
  margin-top: 30px;
}
.table .wrapper {
  margin: 0 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table .header {
  width: 100%;
  margin-bottom: 35px;
}
.table .header .ringDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.table .header .ringDiv span {
  color: #5E5E63;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
}
.table .header .ringDiv svg:first-child {
  margin-right: 25px;
}
.table .header .arrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

